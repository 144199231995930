@use '@styles/_partials' as *;
@use 'sass:math';
@use 'sass:list';
@use './Vote.rtl.scss';

$mobileHeight: 392px;

.voteSection {
  margin-top: 0;
}

.choiceBackgroundsWrapper {
  position: relative;
  height: $mobileHeight;
  width: 100%;

  @include respond-to($lg) {
    height: 490px;
  }
}

.choiceBackgroundsContainer {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  overflow: hidden;
  div {
    height: 200%;
    width: 100%;
    margin-top: math.div($mobileHeight, -2);

    background-image: linear-gradient(
      var(--left-color),
      var(--left-color) 50%,
      var(--right-color) 50%,
      var(--right-color) 100%
    );

    transform: translateY(var(--translate-background));

    @include respond-to($lg) {
      height: 100%;
      width: 200%;
      margin-left: -50%;
      margin-top: 0;
      background-image: linear-gradient(
        90deg,
        var(--left-color),
        var(--left-color) 50%,
        var(--right-color) 50%,
        var(--right-color) 100%
      );
      transform: translateX(var(--translate-background));
    }
  }
}

.choiceBoxesContainer {
  height: 100%;
  width: 100%;
  color: $white;
  display: flex;
  flex-direction: column;

  @include respond-to($lg) {
    flex-direction: row;
    transform: translateX(var(--translate-foreground));
  }
}

.choiceBox {
  height: math.div($mobileHeight, 2);
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  @include respond-to($lg) {
    flex-direction: row;
    height: 100%;
    width: 50%;
  }

  &.left {
    justify-content: flex-end;
  }
  &.right {
    justify-content: flex-start;
  }
}

.choiceTitleText {
  @extend %visibleAtStart;
  display: none;
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  @include font-size(25px);
  text-align: center;
  font-family: $primaryFontBold;

  @include respond-to($lg) {
    display: block;
  }
}

.mobileEyebrowText {
  position: absolute;
  left: 0;
  right: 0;
  top: 72px;
  @include font-size(16px);
  text-align: center;
  font-family: $primaryFontBold;

  @include respond-to($lg) {
    display: none;
  }
}

.choiceAndCheckbox {
  @extend %visibleAtStart;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  width: 156px;
  height: 145px;
  margin: auto pxToRem(35px) auto auto;

  .left & {
    flex-direction: column-reverse;
  }

  .right & {
    flex-direction: column;
  }

  @include respond-to($md) {
    width: 75%;
    text-align: center;
  }

  @include respond-to($lg) {
    margin: pxToRem(100px) pxToRem(90px);
    height: 94px;
    width: unset;
    justify-content: flex-end;

    .left & {
      flex-direction: row-reverse;
    }

    .right & {
      flex-direction: row;
    }
  }
}

%visibleAtStart {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s, visibility 0s 0.2s;
  .startState & {
    opacity: 1;
    visibility: visible;
  }
}

.checkboxLabel {
  text-transform: uppercase;
  @include font-size(20px);
  @include line-height(25);
  font-family: $primaryFontHeavy;
  cursor: pointer;

  @include respond-to($md) {
    @include font-size(30px);
    @include line-height(35);
  }

  @include respond-to($lg) {
    @include font-size(38px);
    @include line-height(44);
    .left & {
      padding-right: pxToRem(95px);
    }

    .right & {
      padding-left: pxToRem(95px);
    }
  }

  @include respond-to($xl) {
    @include font-size(50px);
    @include line-height(54);
  }

  // Increase font at larger breakpoint so longer text doesn't cutoff
  @include respond-to($xxl) {
    @include font-size(70px);
    @include line-height(74);
  }
}

.bigCheckboxWrapper {
  width: 50px;
  height: 50px;
  overflow: visible;
  position: relative;

  @include respond-to($lg) {
    width: 89px;
    height: 89px;
    position: absolute;
    bottom: -110px;
    left: 0;
    right: 0;
    // margin: 0 auto;
    margin: unset;
    bottom: unset;
    &.left {
      top: 0;
      right: 0;
      left: initial;
    }
    .right {
      top: 0;
      right: initial;
      left: 0;
    }
  }
  // @include respond-to($xl) {
  //   margin: unset;
  //   bottom: unset;
  //   &.left {
  //     top: 0;
  //     right: 0;
  //     left: initial;
  //   }
  //   .right {
  //     top: 0;
  //     right: initial;
  //     left: 0;
  //   }
  // }
}

.bigCheckbox {
  width: 100%;
  height: 100%;
  cursor: pointer;
  border: 2px solid $white;
  background-color: transparent;

  @include respond-to($lg) {
    border-width: 3px;
  }

  &[aria-checked='true']::before {
    content: '';
    background-repeat: no-repeat;
    position: absolute;
    left: 8px;
    bottom: 9px;
    width: 45px;
    height: 45px;
    background-image: url('../../../public/images/inputs/big-checkmark.svg');
    background-size: 45px 45px;

    @include respond-to($lg) {
      left: 20px;
      bottom: 15px;
      width: 100px;
      height: 100px;
      background-size: 100px 100px;
    }
  }
}

.choiceSeparator {
  position: absolute;
  top: 46%;
  right: pxToRem(35px);
  text-align: center;
  width: 156px;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s;

  .startState & {
    opacity: 1;
  }

  @include respond-to($md) {
    width: 75%;
  }

  @include respond-to($lg) {
    top: 122px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  img {
    max-width: 100%;
  }
}

.voteImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.choicePercent {
  position: absolute;
  transition: opacity 0.2s;
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;

  .startState & {
    opacity: 0;
    visibility: hidden;
  }
  text-align: center;

  right: 0;
  bottom: 30px;

  @include respond-to-max-width($maxLg) {
    $baseTranslation: list.slash(
      calc(#{$mobileHeight} * var(--vote-offset)),
      -200
    );
    .left & {
      transform: translateY(max(#{$baseTranslation}, -46px));
    }

    .right & {
      transform: translateY(min(#{$baseTranslation}, 46px));
    }
  }

  @include respond-to($lg) {
    transform: translateX(var(--translate-result));
    top: 25px;
    bottom: unset;
    right: unset;
    width: unset;
    .left & {
      right: 9.4vw;
      left: unset;
    }

    .right & {
      top: 25px;
      left: 9.4vw;
    }
  }

  @include respond-to($xl) {
    top: 60px;
    .right & {
      top: 60px;
    }
  }
}

.choicePercentLabel {
  text-transform: uppercase;
  @include font-size(27px);
  @include line-height(30);
  font-family: $primaryFontHeavy;

  @include respond-to($lg) {
    @include font-size(42px);
    @include line-height(47);
    max-width: 240px;
  }

  @include respond-to($xl) {
    @include font-size(56px);
    @include line-height(60);
    max-width: unset;
  }
}

.choicePercentNumber {
  display: flex;
  @include font-size(81px);
  @include line-height(82);
  font-family: $primaryFontHeavy;

  @include respond-to($lg) {
    @include font-size(154px);
    @include line-height(155);
  }

  @include respond-to($xl) {
    @include font-size(169px);
    @include line-height(170);
  }
}

.choicePercentSign {
  font-family: $primaryFontHeavy;
  @include font-size(37px);
  @include line-height(46);

  @include respond-to($lg) {
    @include font-size(77px);
    @include line-height(110);
  }
}

.choiceImageContainer {
  position: absolute;
  max-height: 185px;
  max-width: 161px;
  height: 100%;
  width: 42%;
  right: 0;
  left: 10px;

  .left & {
    bottom: 55px;
  }

  .right & {
    top: 55px;
  }

  @include respond-to($lg) {
    max-height: 332px;
    max-width: 290px;
    text-align: center;

    .left & {
      top: 270px;
      right: 15px;
      left: unset;
    }

    .right & {
      top: 270px;
      right: unset;
      left: 15px;
    }

    transform: translateX(var(--translate-bags));
  }
}

.descriptionArea {
  height: auto;
  min-height: 360px;
  max-width: 300px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: pxToRem(70px);

  @include respond-to-max-width($maxLg) {
    .startState & {
      padding-top: pxToRem(70px);
    }
  }

  @include respond-to($lg) {
    max-width: none;

    height: 406px;
    padding-top: pxToRem(126px);
  }
}

.descriptionTitle {
  padding: 0 pxToRem(10px);
  font-family: $primaryFontBold;
  @include font-size(22px);

  @include respond-to($lg) {
    @include font-size(40px);
  }
}

.descriptionSubtitle {
  margin-top: pxToRem(20px);
  margin-bottom: pxToRem(30px);
  max-width: 760px;

  @include font-size(18px);
  @include line-height(28);

  @include respond-to($lg) {
    margin-top: pxToRem(40px);
    @include font-size(24px);
    @include line-height(34);
  }
}

.loadingSpinnerMessage {
  @include font-size(18px);
  @include line-height(20);
  font-family: $primaryFontBold;
  height: 40px;

  @include respond-to($lg) {
    @include font-size(36px);
    @include line-height(50);
    height: unset;
  }

  @include respond-to($xl) {
    @include font-size(40px);
    @include line-height(55);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .choiceBox,
  .descriptionTitle,
  .descriptionSubtitle {
    opacity: 0;
  }
  .choiceImageContainer,
  .choiceSeparator,
  .choiceTitleText,
  .submitButton {
    transform: scale(0);
  }

  .isInViewport {
    .choiceBox {
      animation: revealItem forwards $animationDuration;
      &.left {
        animation-delay: $animationDelay;
      }
      &.right {
        animation-delay: calc($animationDelay + $animationDelayAddend * 2);
      }
    }
    .descriptionTitle,
    .descriptionSubtitle {
      animation: revealItem forwards $animationDuration;
      animation-delay: calc($animationDelay + $animationDelayAddend * 3);
    }

    .choiceSeparator,
    .choiceImageContainer,
    .choiceTitleText,
    .submitButton {
      animation: scaleToFull forwards $animationDuration;
    }
    .choiceSeparator {
      animation-delay: calc($animationDelay + $animationDelayAddend);
    }
    .submitButton {
      animation-delay: calc($animationDelay + $animationDelayAddend * 3);
    }
  }
}

.srOnly {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.productImage {
  height: 100%;
  text-align: center;
  img {
    width: unset;
    height: 100%;
  }
}

.versus {
  background-color: $white;
  display: inline-block;
  transform: rotate(-5deg) skew(-15deg);
  padding-left: pxToRem(5px);
  padding-right: pxToRem(5px);

  @include respond-to($md) {
    padding-left: pxToRem(8px);
    padding-right: pxToRem(8px);
  }

  @include respond-to($lg) {
    padding-right: pxToRem(12px);
  }

  span {
    font-family: $primaryFontHeavy;
    text-transform: uppercase;
    color: $black;
    margin: 0;
    transform: rotate(-5deg) skew(15deg);
    @include font-size(18px);
    @include line-height(24);
    @include letter-spacing(0);

    @include respond-to($md) {
      @include font-size(26px);
      @include line-height(32px);
    }

    @include respond-to($lg) {
      font-size: pxToRem(31px);
      letter-spacing: pxToRem(-0.4px);
      line-height: pxToRem(42px);
      @include font-size(31px);
      @include line-height(42px);
      @include letter-spacing(-0.4px);
    }
  }
}
