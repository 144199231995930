@use '@styles/_partials' as *;
@use '@styles/components/Switch.scss' as *;

:global(html[dir='rtl']) {
  .choiceBackgroundsContainer {
    div {
      background-image: linear-gradient(
        0deg,
        var(--left-color),
        var(--left-color) 50%,
        var(--right-color) 50%,
        var(--right-color) 100%
      );
      transform: translateY(calc(-1 * var(--translate-background)));
      @include respond-to($lg) {
        margin-left: 0;
        margin-right: -50%;
        background-image: linear-gradient(
          90deg,
          var(--left-color),
          var(--left-color) 50%,
          var(--right-color) 50%,
          var(--right-color) 100%
        );
        transform: translateX(var(--translate-background));
      }
    }
  }

  .choiceImageContainer {
    right: unset;
    left: 0;
  }

  .left {
    @include respond-to($lg) {
      .choiceImageContainer {
        right: unset;
        left: 0;
      }
      .checkboxLabel {
        padding-right: 0;
        padding-left: pxToRem(95px);
      }
      .bigCheckboxWrapper {
        right: unset;
        left: 0;
      }
    }
  }

  .right {
    @include respond-to($lg) {
      .choiceImageContainer {
        right: 0;
        left: unset;
      }
      .checkboxLabel {
        padding-right: pxToRem(95px);
        padding-left: 0;
      }
    }
  }

  .srOnly {
    left: 10000px;
  }
}
